@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
	$config: mat.m2-get-color-config($theme);
	$foreground: map.get($config, foreground);

	.sh-chip-input-field {
		color: mat.m2-get-color-from-palette($foreground, text);
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);
	@if $color-config != null {
		@include color($theme);
	}
}
