/**
 * Do not edit directly
 * Generated by StyleDictionary
 * Prism Design Tokens version: 2.0.0.123
 */


@import url("_common.css");
[prism-icon="sh-network"],
[prism-icon-family="md"] [prism-icon="sh-network"],
[prism-icon-family="md"][prism-icon="sh-network"] {
    --mask-image: url("data:image/svg+xml;utf8,%3C!--%20Generated%20by%20IcoMoon.io%20--%3E%0A%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%3Ctitle%3Esh-icon-network_sh-icon-network%3C%2Ftitle%3E%0A%3Cpath%20d%3D%22M22%2015v5h-5v-5h1v-2h-5v2h1.5v5h-5v-5h1.5v-2h-5v2h1v5h-5v-5h2v-4h7v-2h-1.5v-5h5v5h-1.5v2h7v4h2z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A");
    mask-image: var(--mask-image);
    -webkit-mask-image: var(--mask-image);
}
