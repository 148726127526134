$sh-layout-padding: 24px !default;
$sh-content-padding: 24px !default;

html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

body {
	> * {
		display: flex;
		flex: 1;
		flex-direction: column;
		box-sizing: border-box;
		width: 100%;
	}
}

[sh-form-max-width],
.sh-form-max-width {
	max-width: 800px;
}

// Add flex properties to component inserted after outlet & remove from outlet
router-outlet:not([noFlex]) {
	flex: none !important;

	+ * {
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		box-sizing: border-box;
		position: relative;
		overflow: hidden;
	}
}

[sh-layout-padding],
.sh-layout-padding {
	padding: $sh-layout-padding;
}

[sh-content-padding],
.sh-content-padding {
	padding: $sh-content-padding;
}

[sh-content-padding-revoke],
.sh-content-padding-revoke {
	margin-right: -$sh-content-padding;
	margin-left: -$sh-content-padding;
	width: calc(100% + (#{$sh-content-padding} * 2));
}

[sh-ellipsis],
.sh-ellipsis {
	max-width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: var(
		--mat-foreground-secondary-text
	); // rgba(var(--mat-foreground-text-on-background), 0.6); // background: mat-color($primary, 700, 0.6);
}

::-webkit-scrollbar-track {
	border-radius: 0;
}

[sh-scrollbar]::-webkit-scrollbar-button,
[sh-scrollbar]::-webkit-scrollbar-track-piece,
[sh-scrollbar]::-webkit-scrollbar-corner,
[sh-scrollbar]::-webkit-resizer,
.sh-scrollbar::-webkit-scrollbar-button,
.sh-scrollbar::-webkit-scrollbar-track-piece,
.sh-scrollbar::-webkit-scrollbar-corner,
.sh-scrollbar::-webkit-resizer {
	display: none;
}

[sh-scrollbar]:hover::-webkit-scrollbar,
.sh-scrollbar:hover::-webkit-scrollbar {
	display: initial;
}

[sh-scrollbar]::-webkit-scrollbar,
.sh-scrollbar::-webkit-scrollbar {
	display: none;
}

[sh-scrollbar],
.sh-scrollbar {
	overflow-y: overlay !important;
}

[sh-scrollbar-x],
.sh-scrollbar-x {
	overflow-x: overlay !important;
}

.safari {
	[sh-scrollbar]::-webkit-scrollbar,
	.sh-scrollbar::-webkit-scrollbar {
		display: initial;
	}
}

// TODO: LBF 10/4/22 adding
.mat-table {
	border-radius: 8px;
}
