/**
 * Do not edit directly
 * Generated by StyleDictionary
 * Prism Design Tokens version: 2.0.0.123
 */


@import url("_common.css");
[prism-icon="sh-error-outline"],
[prism-icon-family="md"] [prism-icon="sh-error-outline"],
[prism-icon-family="md"][prism-icon="sh-error-outline"] {
    --mask-image: url("data:image/svg+xml;utf8,%3C!--%20Generated%20by%20IcoMoon.io%20--%3E%0A%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%3Ctitle%3Esh-error-outline%3C%2Ftitle%3E%0A%3Cpath%20d%3D%22M16.142%2022h-8.284l-5.858-5.858v-8.284l5.858-5.858h8.285l5.857%205.858v8.285zM8.686%2020h6.627l4.687-4.686v-6.628l-4.686-4.686h-6.628l-4.686%204.686v6.627z%22%3E%3C%2Fpath%3E%0A%3Cpath%20d%3D%22M11%207h2v6h-2v-6z%22%3E%3C%2Fpath%3E%0A%3Cpath%20d%3D%22M11%2015h2v2h-2v-2z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A");
    mask-image: var(--mask-image);
    -webkit-mask-image: var(--mask-image);
}
