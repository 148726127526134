@use 'sass:map';

@mixin color($led-colors) {
	sh-confirm-dialog {
		.sh-warning-icon {
			color: map.get($led-colors, 'bad');
		}
	}
}

@mixin theme($led-colors) {
	@if $led-colors != null {
		@include color($led-colors);
	}
}
