/**
 * Do not edit directly
 * Generated by StyleDictionary
 * Prism Design Tokens version: 3.0.0.128
 */


@import url("_common.css");
[prism-icon="help"],
[prism-icon-family="md"] [prism-icon="help"],
[prism-icon-family="md"][prism-icon="help"] {
    --mask-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M12%202C6.48%202%202%206.48%202%2012s4.48%2010%2010%2010%2010-4.48%2010-10S17.52%202%2012%202zm1%2017h-2v-2h2v2zm2.07-7.75-.9.92C13.45%2012.9%2013%2013.5%2013%2015h-2v-.5c0-1.1.45-2.1%201.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41%200-1.1-.9-2-2-2s-2%20.9-2%202H8c0-2.21%201.79-4%204-4s4%201.79%204%204c0%20.88-.36%201.68-.93%202.25z%22%2F%3E%3C%2Fsvg%3E");
    mask-image: var(--mask-image);
    -webkit-mask-image: var(--mask-image);
}
