@use '../styles/material-overrides/material-overrides.scss' as mat-override;
@use '../styles/scrollbar.scss' as scrollbar;
@use '../styles/inline-link.scss' as inline-link;
@use '../styles/secondary-text.scss' as secondary-text;

/**
 * Overrides for @angular/material components and other common elements.
 */
@mixin all-theme-overrides($theme) {
	@include mat-override.all-components-override-theme($theme);
	@include scrollbar.theme($theme);
	@include inline-link.theme($theme);
	@include secondary-text.theme($theme);
}
