@mixin override-style() {
	.mat-mdc-dialog-surface {
		padding: 24px;
		border-radius: 8px !important;
	}

	.sh-dialog-no-padding .mat-mdc-dialog-surface {
		padding: 0;
	}

	.sh-dialog-rounded-corners .mat-mdc-dialog-surface {
		border-radius: 8px !important;
	}

	.sh-dialog-overflow-hidden .mat-mdc-dialog-surface {
		overflow-y: hidden;
	}

	.mat-mdc-dialog-container .mdc-dialog__surface {
		background-color: var(--prism-color-theme-surface-variant) !important;
	}
}
