@mixin override-style() {
	.mat-mdc-slide-toggle {
		.mdc-switch__icons {
			display: none;
		}
	}

	.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track {
		opacity: 0.5;
	}
}
