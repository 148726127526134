/*@import './material/mat-button.scss';
@include mat-button-override();

@import './material/mat-card.scss';
@include mat-card-override();*/

@import './material/mat-dialog.scss';
@include mat-dialog-override();

/*@import './material/mat-drawer.scss';
@include mat-drawer-override();*/

@import './material/mat-form.scss';
@include mat-form-override();

/*@import './material/mat-list.scss';
@include mat-list-override();

@import './material/mat-menu.scss';
@include mat-menu-override();

@import './material/mat-nav.scss';
@include mat-nav-override();

@import './material/mat-tabs.scss';
@include mat-tabs-override();*/

@import './material/mat-sidenav.scss';
@include mat-sidenav-override();

/*@import './material/mat-toolbar.scss';
@include mat-toolbar-override();

@import './material/mat-tooltip.scss';
@include mat-tooltip-override();

@import './material/mat-table.scss';
@include mat-table-override();

@import './material/mat-accordian.scss';
@include mat-accordian-override();

@import './material/mat-icon.scss';
@include mat-icon-override();

@import './material/mat-select.scss';
@include mat-select-override();

@import './material/mat-checkbox.scss';
@include mat-checkbox-override();

@import './material/cdk-virtual-scroll-viewport.scss';
@include cdk-virtual-scroll-viewport-override();*/
