@use 'sass:map';
@use '@angular/material' as mat;

@mixin override-theme($theme) {
	$color-config: mat.m2-get-color-config($theme);
	$foreground: map.get($color-config, foreground);
	$accent: map.get($color-config, accent);

	.mat-form-field {
		// Allow for mat-form-fields to flex correctly
		.mat-mdc-form-text-infix {
			width: unset;
		}
	}

	.mat-mdc-floating-label {
		color: #6e6e6e !important;
	}
	.sh-readonly {
		.mdc-line-ripple:read-only {
			display: none;
		}
	}

	.mat-form-field-disabled.mat-form-field-type-mat-input {
		.mdc-line-ripple {
			display: none;
		}
		input.mat-input-element[readonly][disabled] {
			pointer-events: none;
			color: mat.m2-get-color-from-palette($foreground, text);
		}
	}
}
