@use 'sass:map';
@use '@angular/material' as mat;

/**
 * `sh-inline-link` defines the style for a inline-link w. a underline
 */
@mixin theme($theme) {
	$config: mat.m2-get-color-config($theme);
	$foreground: map.get($config, foreground);

	.sh-secondary-text {
		color: mat.m2-get-color-from-palette($foreground, secondary-text);
	}
}
