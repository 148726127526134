@use './components/mat-button.scss' as mat-button;
@use './components/mat-slide-toggle.scss' as mat-slide-toggle;
@use './components/mat-dialog.scss' as mat-dialog;
@use './components/mat-menu.scss' as mat-menu;
@use './components/mat-toolbar.scss' as mat-toolbar;
@use './components/mat-select.scss' as mat-select;
@use './components/mat-input.scss' as mat-input;
@use './components/mat-expansion-panel.scss' as mat-expansion-panel;
@use './components/mat-list.scss' as mat-list;
@use './components/mat-table.scss' as mat-table;
@use './components/mat-form-field.scss' as mat-form-field;
@use './components/mat-slider.scss' as mat-slider;
/**
	Mixin for overriding angular material component styles with
	Shure UX specifications.
*/

@mixin all-components-override-style() {
	@include mat-button.override-style();
	@include mat-dialog.override-style();
	@include mat-menu.override-style();
	@include mat-toolbar.override-style();
	@include mat-table.override-style();
	@include mat-expansion-panel.override-style();
	@include mat-form-field.override-style();
	@include mat-slide-toggle.override-style();
	@include mat-select.override-style();
	@include mat-slider.override-style();
}

/**
Mixin for overriding angular material component theme with
Shure UX specifications.
*/
@mixin all-components-override-theme($theme) {
	@include mat-select.override-theme($theme);
	@include mat-input.override-theme($theme);
	@include mat-expansion-panel.override-theme($theme);
	@include mat-list.override-theme($theme);
	@include mat-table.override-theme($theme);
	@include mat-slider.override-theme($theme);
}
