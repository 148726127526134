@use 'sass:map';
@use '@angular/material' as mat;

@mixin override-theme($theme) {
	$config: mat.m2-get-color-config($theme);
	$accent: map.get($config, accent);
	$foreground: map.get($config, foreground);

	.mat-mdc-table {
		border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider) !important;
		.sh-row-selected td {
			background-color: mat.m2-get-color-from-palette($accent, 0.12);
		}
		.mat-mdc-header-cell {
			color: mat.m2-get-color-from-palette($foreground, secondary-text);
		}
	}
}

@mixin override-style() {
	.mat-mdc-table {
		.mat-mdc-header-cell {
			font-size: 12px;
			font-weight: 500;
		}
	}
}
