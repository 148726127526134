:where([prism-icon], .prism-icon) {
    --size: 1.5rem; /* 24px, but scales with font size */
    --mask-image: url("../assets/icons/md/star.svg"); /* fallback icon */
    display: inline-block;
    width: var(--size);
    height: var(--size);
    background-color: currentcolor;
    mask-image: var(--mask-image);
    -webkit-mask-image: var(--mask-image);
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: contain;
    -webkit-mask-size: contain;
    mask-position: center;
    -webkit-mask-position: center;
}

[prism-icon-size="sm"],
.prism-icon-sm {
    --size: 1.125rem;
}

[prism-icon-size="lg"],
.prism-icon-lg {
    --size: 2.5rem;
}
