/**
 * Do not edit directly
 * Generated by StyleDictionary
 * Prism Design Tokens version: 2.0.0.123
 */


@import url("_common.css");
[prism-icon="settings"],
[prism-icon-family="md"] [prism-icon="settings"],
[prism-icon-family="md"][prism-icon="settings"] {
    --mask-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22m19.44%2012.99-.01.02c.04-.33.08-.67.08-1.01%200-.34-.03-.66-.07-.99l.01.02%202.44-1.92-2.43-4.22-2.87%201.16.01.01c-.52-.4-1.09-.74-1.71-1h.01L14.44%202H9.57l-.44%203.07h.01c-.62.26-1.19.6-1.71%201l.01-.01-2.88-1.17-2.44%204.22%202.44%201.92.01-.02c-.04.33-.07.65-.07.99%200%20.34.03.68.08%201.01l-.01-.02-2.1%201.65-.33.26%202.43%204.2%202.88-1.15-.02-.04c.53.41%201.1.75%201.73%201.01h-.03L9.58%2022h4.85s.03-.18.06-.42l.38-2.65h-.01c.62-.26%201.2-.6%201.73-1.01l-.02.04%202.88%201.15%202.43-4.2s-.14-.12-.33-.26l-2.11-1.66zM12%2015.5c-1.93%200-3.5-1.57-3.5-3.5s1.57-3.5%203.5-3.5%203.5%201.57%203.5%203.5-1.57%203.5-3.5%203.5z%22%2F%3E%3C%2Fsvg%3E");
    mask-image: var(--mask-image);
    -webkit-mask-image: var(--mask-image);
}
